@font-face {
  font-family: Akkurat-Mono;
  font-weight: 500;
  font-style: normal;
  src: url('./Akkurat-Mono.otf') format('opentype');
}

@font-face {
  font-family: Neue Haas Unica;
  src: url('./NeueHaasUnica-Regular.woff') format('woff');
}
